// src/App.js
import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Listings from './components/Listings';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  
  // Static data for demonstration
  const [listings] = useState([
    {
      title: "1000 Toys Hellboy 30th Anniversary 1:12 Scale Action Figure",
      price: "$19.99",
      imageUrl: "https://m.media-amazon.com/images/I/71X6JAHbmmL._AC_SX679_.jpg",
      link: "https://amzn.to/3Tao050"
    },
    {
      title: "PlayStation VR2 PC Adapter",
      price: "$59.99",
      imageUrl: "https://m.media-amazon.com/images/I/517P9qQhwML._SX522_.jpg",
      link: "https://amzn.to/3AKeL56"
    },
    {
      title: "Funko! Pop Animation: Jujutsu Kaisen - Toge Inumaki with Chase",
      price: "$11.99",
      imageUrl: "https://m.media-amazon.com/images/I/51q23NxE11L.__AC_SX300_SY300_QL70_FMwebp_.jpg",
      link: "https://amzn.to/3T8f7J8"
    },
    {
      title: "A Nightmare on Elm Street (40th Anniversary Ultimate Collectors Edition)",
      price: "$84.99",
      imageUrl: "https://m.media-amazon.com/images/I/41T52l4yltL._SX342_.jpg",
      link: "https://amzn.to/3ASnoui"
    },
  ]);
  
  const [recentlistings] = useState([
    {
      title: "Vickerman 6.5' x 42 Flocked Atka Slim Artificial Christmas Tree",
      price: "$306.99",
      imageUrl: "https://c.media-amazon.com/images/I/81dGh12Il9L._AC_SX679_.jpg",
      link: "https://www.amazon.com/dp/B0728F7QPF?th=1"
    },
    {
      title: "SAFAVIEH Hollywood Collection Area Rug - 6'7 Square",
      price: "$148.99",
      imageUrl: "https://c.media-amazon.com/images/I/91dLDoDwgcL._AC_SY879_.jpg",
      link: "https://www.amazon.com/dp/B0728F7QPF?th=1"
    },
    {
      title: "3 Pack Niacinamide Serum with Ceramide & Snail Secretion",
      price: "$8.99",
      imageUrl: "https://c.media-amazon.com/images/I/71Ls4akYASL._SX679_.jpg",
      link: "https://www.amazon.com/dp/B0CXT9MTJN"
    },
    {
      title: "KALLORY Organizing Box Bottles Essential Oil Storage Carrying Case",
      price: "$8.99",
      imageUrl: "https://c.media-amazon.com/images/I/51tfNLYfQ-L._AC_SX679_.jpg",
      link: "https://www.amazon.com/dp/B0B282KFN4"
    }, 
    {
      title: "Forney 75356 Air Inflator Needle with 1/8 Forney 75356 Air Inflator Needle with 1/8-Inch Male NPT Adapter,",
      price: "$1.10",
      imageUrl: "https://c.media-amazon.com/images/I/41+oAVP+boL._AC_SX679_PIbundle-2,TopRight,0,0_SH20_.jpg",
      link: "https://www.amazon.com/dp/B0CF2Y49QR"
    }, 
    {
      title: "2x3 Washable Rug Non-Slip and Stain Resistant, Black and White Stripes Rug",
      price: "$12.99",
      imageUrl: "https://c.media-amazon.com/images/I/81zOesZoAHL._AC_SX679_.jpg",
      link: "https://www.amazon.com/dp/B0D5M9LJ54"
    }, 
    {
      title: "Tattoo Machine Gun Kit with Tattoo Rotary Pen -",
      price: "$17.99",
      imageUrl: "https://c.media-amazon.com/images/I/71FwYxItH-L._SX679_.jpg",
      link: "https://www.amazon.com/dp/B0C6SC3DKY"
    }, 
    {
      title: "Thermaltake Riing Quad 14 RGB Radiator Fan TT Premium Edition Single Fan Pack",
      price: "$47.56",
      imageUrl: "https://c.media-amazon.com/images/I/51WpF8XK8jL._AC_SX679_.jpg",
      link: "https://www.amazon.com/dp/B08NF624DL"
    }, 
    {
      title: "CHADWK Bob Wig Human Hair 180% Density Full 13x4",
      price: "$19.99",
      imageUrl: "https://c.media-amazon.com/images/I/81OX2t-jCML._SX679_.jpg",
      link: "https://www.amazon.com/dp/B0CTH21BD7"
    }, 
    {
      title: "Rubbermaid Shed Accessories Multi-Purpose Hook, Individual, Black",
      price: "$30.68",
      imageUrl: "https://c.media-amazon.com/images/I/411qUqIJ57L._AC_SX679_.jpg",
      link: "https://www.amazon.com/dp/B0CDP42MJV"
    }, 
    {
      title: "Coverking Custom Fit Seat Cover Designed for Select Ram Models",
      price: "$46.26",
      imageUrl: "https://c.media-amazon.com/images/I/51ILmTNncVL._AC_SX679_.jpg",
      link: "https://www.amazon.com/dp/B0BX4Q726H"
    }, 
  ]);

 

  // Fetching data could be done here
  // useEffect(() => {
  //   fetch('API_URL')
  //     .then(response => response.json())
  //     .then(data => setListings(data));
  // }, []);

  return (
    <div className="App">
      <Navbar />
      <h1>Amazon Listings</h1>
      <Listings listings={listings} />
      <br></br>
      <h1>Latest Deals</h1>
      <Listings listings={recentlistings} />
      <Footer />
    </div>
  );
};

export default App;
